body {
  margin: 0;
  font-family: 'SF Pro Display';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-height: 100%;
}

p,
h2,
h3 {
  margin: 0;
}

p {
  letter-spacing: -0.02em;
}

h2 {
  letter-spacing: -0.03em;
}

button {
  font-family: 'SF Pro Display';
}

input:focus {
  border-color: #ff9900;
}

/* Home */
.homePage {
  background-color: #ffffff;
}

.homeBackground {
  width: 100%;
  height: auto;
}

.home {
  position: relative;
  top: -90px;
  background-color: white;
  border-radius: 16px 16px 0px 0px;
  padding: 0px 16px;
}

.home h2 {
  font-size: 21px;
  font-weight: 860;
  padding: 24px 0px;
}

.homeSuperpower {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.homeKargo {
  background-color: #fff1d6;
  border-radius: 16px;
  padding: 16px;
  display: flex;
  justify-content: space-between;
}

.homeKargoTexts2 {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.homeKargo img {
  height: 150px;
  width: 150px;
}

.homeTitles {
  font-size: 17px;
  font-weight: 590;
  max-width: 120px;
}

.homeKargoTexts {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.homeDescription {
  font-size: 15px;
  font-weight: 510;
}

.homePrice {
  background-color: white;
  border-radius: 24px;
  padding: 8px 12px;
  font-size: 15px;
  font-weight: 590;
  width: max-content;
}

.homeLogisticsAndCustoms {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;
}

.homeLogistics {
  background-color: #ffd6ec;
  background-image: url(./img/ship.png);
  background-repeat: no-repeat;
  background-position: 10px 50px;
  background-size: 150px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
}

.homeCustoms {
  background-color: #d5ffde;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
}

.homeCustoms .homeTitles,
.homeLogistics .homeTitles {
  padding: 16px 16px 0px 16px;
}

.homeLogistics .homePrice {
  margin: 0px 16px 16px 16px;
}

.homeCustoms img {
  height: 146px;
  width: 150px;
  align-self: end;
  margin: 15px 0px;
}

.homeCar {
  background-color: #d6f1ff;
  border-radius: 16px;
  padding: 16px;
  display: flex;
  justify-content: space-between;
}

.homeCar div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.homeCar img {
  width: 150px;
}

.homeCar .homeTitles {
  max-width: 100px;
}

.homeSuperhero {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.homeSamoVykup {
  background-image: url(./img/samoVycup.png);
  border-radius: 16px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 98px;
}

.homeChinaEscort {
  background-color: #e0e8f1;
  border-radius: 16px;
  padding: 16px;
  height: 98px;
}

.homeChinaEscort .homeTitles {
  max-width: max-content;
}

.homeChinaEscort .homeDescription {
  margin-top: 10px;
  max-width: 200px;
}

.homeArrow p {
  background-color: #131416;
  color: #ffffff;
  border-radius: 24px;
  padding: 8px 10px;
  margin-top: 5px;
  font-weight: 500;
}

.homeArrow {
  display: flex;
  flex-direction: row-reverse;
}

.homeSearch {
  background-color: #ffd6ec;
  background-image: url(./img/magnifer.png);
  background-repeat: no-repeat;
  background-position: 90% 50%;
  background-size: 150px;
  border-radius: 16px;
  padding: 16px;
  height: 153px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.homeDelivery,
.homeFabric {
  background-color: #e0e8f1;
  border-radius: 16px;
  padding: 16px;
  height: 98px;
  width: 50%;
}

.homeDeliveryAndFabric {
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-bottom: -70px;
}

/* Kargo */
.kargo {
  position: absolute;
}

@media (min-width: 200px) and (max-width: 360px) {
  .kargo {
    top: 160px;
  }
}

@media (min-width: 360px) and (max-width: 380px) {
  .kargo {
    top: 180px;
  }
}

@media (min-width: 380px) and (max-width: 400px) {
  .kargo {
    top: 190px;
  }
}

@media (min-width: 400px) and (max-width: 420px) {
  .kargo {
    top: 200px;
  }
}

@media (min-width: 420px) and (max-width: 440px) {
  .kargo {
    top: 210px;
  }
}

@media (min-width: 440px) {
  .kargo {
    top: 210px;
  }
}

.kargoDark {
  background-color: #0000003d;
  border-radius: 24px;
}

.kargoDarkButtons {
  display: flex;
  justify-content: space-between;
}

.kargoDarkButtons button {
  margin: 2px;
  border: none;
  padding: 10px 20px;
  border-radius: 22px;
  width: 50%;
  font-size: 15px;
  font-weight: 590;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  height: 52px;
}

.kargoDescr {
  background-color: #131416;
  color: #ffffff;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 24px 24px 0px 0px;
}

.kargoDescrHide {
  gap: 16px;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.kargoDescrHide div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.kargoDescr button {
  border: 0;
  background-color: transparent;
  color: #ff9900;
  font-size: 15px;
  font-weight: 700;
}

.kargo h2 {
  font-size: 21px;
  font-weight: 860;
}

.kargoDarkDelivery .homeDescription {
  max-width: 240px;
}

.kargoDeliveryInfo {
  background-color: #131416;
  padding-bottom: 40px;
}

.kargoDeliveryInfo form,
.whiteDeliveryInfo form {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.kargoDeliveryPrices {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.kargoDeliveryFinalCity {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top: 1px solid #e0e8f1;
  margin-top: 16px;
  padding-top: 8px;
}

.kargoDeliveryFinalCity p {
  font-size: 15px;
}

.kargoDeliveryFinalCity button {
  color: #ff9900;
  border: none;
  background-color: transparent;
  font-size: 15px;
}

.kargoDeliveryTexts p {
  font-size: 15px;
  font-weight: 510;
}

.kargoDeliveryTextDark {
  color: #ffffff66;
}

.kargoDeliveryTexts {
  border-left: 1px solid #ff9900;
  padding-left: 8px;
}

.kargoDarkDelivery2 .homePrice {
  color: #131416;
}

.kargoCity,
.kargoWeight,
.kargoCategory,
.kargoRates {
  border-radius: 24px;
  padding: 24px 16px;
  background-color: white;
}

.kargoCity h2,
.kargoCategory h2,
.kargoRates h2,
.kargoWeight h2 {
  max-width: max-content;
  margin-bottom: 24px;
}

.kargoCityBtns button,
.kargoCategotiesBtns button,
.feedbackMethodBtns button {
  border: none;
  padding: 8px 12px;
  border-radius: 24px;
  font-size: 15px;
  font-weight: 590;
  gap: 10px;
}

.kargoCityBtns {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
}

.suffix {
  position: relative;
  right: 25px;
  width: 0;
  color: #9aa0a6;
}

.suffix:first-of-type {
  right: 35px;
}

.kargoWeight div,
.whiteDeliveryWeight div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.weightInput,
.volumeInput,
.otherCategoryInput {
  font-size: 15px;
  font-weight: 500;
  font-family: 'SF Pro Display';
  border-radius: 8px;
  border: 2px solid #e0e8f1;
  outline: none;
  padding: 12px;
}

.weightInput,
.volumeInput {
  width: 50%;
}

.kargoCategory .otherCategoryInput {
  margin-top: 24px;
}

.otherCategoryInput {
  width: -webkit-fill-available;
}

.weightInput {
  margin-right: 8px;
}

.weightInput::placeholder,
.volumeInput::placeholder,
.otherCategoryInput::placeholder {
  color: #9aa0a6;
}

.kargoCategotiesBtns {
  display: flex;
  flex-direction: row;
  gap: 8px;
  flex-wrap: wrap;
}

.kargoCategotiesBtns button {
  display: flex;
}

.kargoCategoryIcon {
  margin-right: 10px;
}

.kargoRatesItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #e0e8f1;
  padding: 16px 0px;
}

.kargoRatesItem:last-of-type {
  border-bottom: 0 solid #e0e8f1;
}

.kargoRatesItem p {
  font-size: 15px;
  font-weight: 590;
}

.kargoRatesItem div {
  font-weight: 590;
}

.kargoCalculate {
  background-color: white;
  border-radius: 16px 16px 0px 0px;
  padding: 16px;
  margin-bottom: -100px;
}

.kargoCalculateBtn,
.kargoCityDecrTexts button,
.packageDecrTexts button {
  display: flex;
  justify-content: center;
  width: 100%;
  color: #131416;
  background-color: #ff9900;
  border: 0;
  border-radius: 30px;
  padding: 16px 0px;
  font-size: 17px;
  font-weight: 700;
}

.kargoCityDecr {
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: #13141699;
  height: 100%;
  overflow-y: hidden;
  z-index: 1;
  backdrop-filter: blur(7px);
}

.kargoCityDecrClose {
  height: 100%;
}

.kargoCityDecrOpen {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.kargoCityDecrTexts,
.packageDecrTexts {
  background-color: #ffffff;
  border-radius: 24px 24px 0px 0px;
  padding: 24px 16px 24px 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.kargoCityDecrTexts h2,
.packageDecrTexts h2 {
  font-size: 21px;
  font-weight: 860;
  margin: 0;
}

.kargoCityDecrTexts p {
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
}

.kargoCityDecrTexts div {
  display: flex;
  flex-direction: row;
  justify-content: left;
  gap: 8px;
  color: #13141699;
  align-items: flex-start;
}

.kargoCityDecrTextsInfo p {
  line-height: 18px;
  flex-shrink: 5;
}

/* Request */
.request,
.feedback {
  background-color: #000000;
}

.requestResults,
.feedbackGray {
  background-color: #131416;
  border-radius: 24px 24px 0px 0px;
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding-bottom: 40px;
}

.requestResultsTop {
  color: #ffffff;
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.requestResultsTop h2,
.feedbackTop h2 {
  font-size: 28px;
  font-weight: 860;
  line-height: 30px;
  text-align: center;
}

.requestParams {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 8px;
  flex-wrap: wrap;
}

.requestParams p {
  background-color: #ffffff0f;
  padding: 8px 12px;
  border-radius: 24px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.requestResultsDelivery h2 {
  max-width: 180px;
  font-size: 21px;
  font-weight: 860;
  line-height: 23px;
}

.otherCategoryText {
  color: #ffffff;
  font-size: 18px;
  font-weight: 510;
  padding: 10px 30px 20px 30px;
  text-align: center;
}

.requestSlowDelivery,
.requestFastDelivery {
  border-radius: 24px;
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.requestFastDelivery {
  background-color: #ffffff;
  background-image: url(./img/rocket.png);
  background-repeat: no-repeat;
  background-position: right;
  background-size: 190px;
  margin-bottom: 2px;
}

.requestSlowDelivery {
  background-color: #d6f1ff;
  background-image: url(./img/bike.png);
  background-repeat: no-repeat;
  background-position: right;
  background-size: 190px;
  background-position-y: 10px;
  background-position-x: 95%;
}

.requestTotalPrice {
  font-size: 28px;
  font-weight: 860;
}

.requestPriceKg {
  font-size: 15px;
  font-weight: 510;
  color: #13141666;
}

.requestDeliveryInfo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.requestTime {
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: #131416;
  border-radius: 24px;
  padding: 8px 12px;
  color: #ff9900;
}

.requestShare {
  color: #ff9900;
  display: flex;
  justify-content: space-between;
  padding: 20px 16px;
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
}

.requestShare button {
  border: none;
  background-color: transparent;
  color: #ff9900;
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
}

.share-button,
.requestShare button {
  display: flex;
  align-items: center;
  gap: 8px;
}

.requestPriceInfo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;
  background-color: #fff1d6;
  border-radius: 24px;
  padding: 24px 16px;
}

.requestPriceInfo p {
  font-size: 17px;
  font-weight: 510;
}

/* Feedback */

.feedbackTop form {
  color: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.feedbackTop h2 {
  margin: 35px 16px;
  color: #ffffff;
}

.feedbackForm {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.conditionsGray .feedbackTop .kargoCalculate {
  border-radius: 0;
}

.feedbackForm,
.feedbackConditions {
  background-color: #ffffff;
  border-radius: 24px;
  color: #131416;
  padding: 24px 16px;
}

.feedbackForm h3 {
  font-size: 21px;
  font-weight: 860;
}

.feedbackForm p {
  font-size: 15px;
  font-weight: 860;
  margin-bottom: 8px;
}

.feedbackMethodBtns {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.feedbackConditions {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.feedbackConditions button {
  border: none;
  background-color: transparent;
  color: #ff9900;
  font-size: 15px;
  font-weight: 510;
  padding: 0;
}

.feedbackConditions p,
.feedbackTime p {
  font-size: 15px;
  font-weight: 510;
}

.feedbackTime {
  color: #131416;
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-top: 24px;
  margin-bottom: 5px;
  align-items: center;
}

/* Conditions */

.conditions {
  background-color: #ffffff;
  border-radius: 24px 24px 0px 0px;
  color: #131416;
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.conditions p {
  font-size: 17px;
  font-weight: 400;
  line-height: 21px;
}

.conditionsGray {
  background-color: #131416;
  border-radius: 24px 24px 0px 0px;
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.conditionsUl,
.packageDecrTexts ul {
  margin: 0;
  padding-left: 20px;
  list-style: '';
}

.conditionsUl li,
.packageDecrTexts ul li {
  list-style-position: outside;
}

.conditionsUl li::before,
.packageDecrTexts ul li::before {
  content: '';
  display: inline-block;
  height: 3px;
  width: 3px;
  vertical-align: middle;
  border-radius: 50%;
  background-color: #131416;
  margin-right: 10px;
  margin-left: -13px;
}

/* RequestSubmitted */

.requestSubmittedBg {
  background-color: #000000;
}

.requestSubmittedTop {
  height: 380px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 16px 24px 16px;
}

.requestSubmitted {
  background: linear-gradient(#131416 50%, #ffffff 50%);
  border-radius: 24px 24px 0px 0px;
}

.requestSubmittedTop img {
  width: 128px;
  height: 128px;
  margin-bottom: 24px;
}

.requestSubmittedTop h2 {
  color: #ffffff;
  font-size: 28px;
  font-weight: 860;
  line-height: 30px;
  max-width: 200px;
  text-align: center;
  margin-bottom: 16px;
}

.requestSubmittedTop p {
  color: #ffffff;
  font-size: 17px;
  font-weight: 400;
  text-align: center;
  padding: 0px 10px;
}

/* WhiteDelivery */

.whiteDeliveryInfo {
  background-color: #131416;
  padding-bottom: 40px;
  width: 100vw;
}

.whiteDeliveryWhatCarrying,
.whiteDeliveryСonditions,
.whiteDeliveryAddress,
.whiteDeliveryProfitableServices,
.whiteDeliveryOtherInfo {
  border-radius: 24px;
  padding: 24px 16px;
  background-color: white;
}

.whiteDeliveryP {
  font-size: 15px;
  font-weight: 860;
}

.whiteDeliveryP2 {
  font-size: 15px;
  font-weight: 400;
}

.whiteDeliveryWhatCarrying,
.whiteDeliveryСonditions,
.whiteDeliveryAddress,
.whiteDeliveryProfitableServices,
.whiteDeliveryOtherInfo {
  display: flex;
  flex-direction: column;
}

.whiteDeliveryWhatCarrying,
.whiteDeliveryAddress,
.whiteDeliveryProfitableServices,
.whiteDeliveryOtherInfo {
  gap: 24px;
}

.whiteDeliveryСonditionsH {
  margin-bottom: 24px;
}

.whiteDeliveryDescrAndInput {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.deliveryDecr {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;
  margin-top: 24px;
  align-items: center;
}

.deliveryDecr h2 {
  font-size: 28px;
  font-weight: 860;
}

.deliveryDecr p {
  font-size: 15px;
  font-weight: 590;
}

/* PackagingTariffs */

.packagingTariff {
  color: #131416;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 2px;
}

.packagingTariffTwo,
.packagingTariffOne,
.packagingTariffThree {
  background-color: #ffffff;
  border-radius: 24px;
  padding: 24px 16px 16px 16px;
  align-items: center;
}

.packagingTariffTwo,
.packagingTariffThree {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
}

.packagingTariffTwo {
  height: 190px;
}

.packagingTariffThree {
  height: 222px;
}

.packagingTariffOne {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.packagingTariff img {
  height: 80px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
}

.packagingTariffOne img {
  margin-right: 12px;
  margin-left: 12px;
}

.packagingTariffOneBtn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.packagingTariffOneBtn button {
  border: none;
  background-color: #131416;
  border-radius: 24px;
  color: #ffffff;
  padding: 0px 10px;
  font-size: 18px;
  font-weight: 860;
}

.packagingTariffOneTitles {
  width: 100%;
}

.packagingTariffOneTitles p,
.packagingTariffThree p {
  font-size: 15px;
  font-weight: 590;
}

.packagingTariffTwo .packagingTariffOneTitles p,
.packagingTariffThree .packagingTariffOneTitles p {
  margin-bottom: 16px;
  padding-right: 10px;
}

.packagingTariffThree .packagingTariffOneTitles p {
  padding-right: 20px;
}

.packagingTariffOne .packagingTariffOneTitles p {
  margin-bottom: 8px;
}

.packagingTariffOneTitles h2 {
  font-size: 28px;
  font-weight: 860;
  line-height: 30px;
}

.packagingTariffOneTitles span {
  font-size: 15px;
  font-weight: 510;
  color: #13141666;
}

.packagingTariffThree .packagingTariffOneBtn button {
  height: min-content;
  padding: 4.5px 10px;
  align-self: flex-end;
}

.packagingDescrPrice {
  display: flex;
  flex-direction: column;
}

.packagingDescrPriceNum {
  font-size: 28px;
  font-weight: 860;
  line-height: 30px;
}

.packageDecrTexts ul {
  font-size: 15px;
  font-weight: 500;
}

.packagingDescrPriceText {
  color: #13141666;
}

.packagingP,
.packagingDescrPriceText {
  font-size: 15px;
  font-weight: 500;
}

/* InsuranceTariff */
.insuranceTariff {
  color: #131416;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2px;
}

.insuranceTariff1,
.insuranceTariff3 {
  background-color: #ffffff;
  border-radius: 24px;
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.insuranceTariffPrices {
  display: flex;
  flex-wrap: wrap;
  gap: 2px;
}

.insuranceTariffPricesDiv,
.deliveryTariffsPricesDiv {
  background-color: #e0e8f1;
  border-radius: 24px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.insuranceTariffPricesDiv {
  width: calc(((100% - 66px) / 2));
}

.insuranceTariffPricesDiv h2,
.deliveryTariffsPricesDiv h2 {
  font-size: 28px;
  font-weight: 860;
  line-height: 30px;
}

.insuranceTariffPricesDiv p,
.deliveryTariffsPricesDiv p {
  font-size: 17px;
  font-weight: 400;
  line-height: 21px;
}

.insuranceTariffP {
  font-size: 17px;
  font-weight: 400;
  line-height: 21px;
}

.insuranceTariff1H {
  font-size: 21px;
  font-weight: 860;
}

/* DeliveryTarrifs */

.deliveryTariffsWarning {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.deliveryTariffsPrices {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.feedbackForm .fillingError p {
  margin-top: auto;
  margin-bottom: auto;
}

.feedbackForm .fillingError {
  margin-top: 8px;
}

.kargoDeliveryInfo .fillingError {
  margin-top: 16px;
}

.kargoDeliveryInfo .fillingError,
.feedbackForm .fillingError {
  display: flex;
  flex-direction: row;
  color: #ff4d00;
  align-items: center;
  gap: 8px;
}

.whiteDeliveryDescrAndInput .fillingError {
  margin-top: 8px;
}

.fillingError {
  display: flex;
  flex-direction: row;
  color: #ff4d00;
  align-items: center;
  gap: 8px;
}

.fillingError p {
  font-size: 15px;
  font-weight: 500;
}

/* Loader */
.loader {
  display: flex;
  margin: auto;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('https://raw.githubusercontent.com/blaisck/sfwin/master/SFPro/TrueType/SFProDisplay-SemiBold.ttf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('https://raw.githubusercontent.com/blaisck/sfwin/master/SFPro/TrueType/SFProDisplay-Medium.ttf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('https://raw.githubusercontent.com/blaisck/sfwin/master/SFPro/TrueType/SFProDisplay-Heavy.ttf');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('https://raw.githubusercontent.com/blaisck/sfwin/master/SFPro/TrueType/SFProDisplay-Bold.ttf');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('https://raw.githubusercontent.com/blaisck/sfwin/master/SFPro/TrueType/SFProDisplay-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}
